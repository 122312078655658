//= require cleave.js/dist/cleave.js
//= require cleave.js/dist/addons/cleave-phone.fr.js

(function($) {
  $(function() {
    if ($('#step2_form_turnover').length > 0) {
      var turnover = new Cleave('#step2_form_turnover', {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalMark: ',',
        delimiter: ' '
      });
      var turnoverFrance = new Cleave('#step2_form_turnover_distribution_france', {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalMark: ',',
        delimiter: ' '
      });
      var turnoverOutsideFrance = new Cleave('#step2_form_turnover_distribution_outside_france', {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalMark: ',',
        delimiter: ' '
      });
    }

    if ($('#step5_form_phone').length > 0) {
      var phone = new Cleave('#step5_form_phone', {
        phone: true,
        phoneRegionCode: 'FR'
      });
    }

    // if ($('body').hasClass('env-development')) {
    //   $('#acf-field_5ff5519ab2309').val('M JOHN SMITH');
    //   $('#acf-field_5ff55166b2307').val('BNP PARIBAS GUYANE SA');
    //   $('#acf-field_5ff55186b2308').val('2 Rue de la Huguenoterie, 35000 Rennes');
    //   $('#acf-field_5fdf26b8e57ba').val('FR7217569000506542765766U21');
    //   $('#acf-field_5fdf26c2e57bb').val('BNPAGFGX');
    // }

    // if ($('.js-cleave-phone').length > 0) {
    // }

    // function syncShadow($input, $shadow, placeholder) {
    //   $input.on('keydown', function(e) {
    //     var char = String.fromCharCode(e.keyCode);
    //     if (!char.match(/[a-z0-9]/i)) return;

    //     var value = ($input.val() + char).toUpperCase();
    //     var update_placeholder = value + placeholder.substring(value.length, placeholder.length);
    //     $shadow.val(update_placeholder);
    //   });

    //   $input.on('keyup', function(e) {
    //     var value = $input.val();
    //     var update_placeholder = value + placeholder.substring(value.length, placeholder.length);
    //     $shadow.val(update_placeholder);
    //   }).trigger('keyup');
    // }

    // var $iban = $('.js-cleave-iban');
    // if ($iban.length > 0) {
    //   var cleaveIban = new Cleave('.js-cleave-iban', {
    //     delimiter: ' ',
    //     blocks: [4, 4, 4, 4, 4, 4, 3],
    //     uppercase: true
    //   });

    //   var $iban_shadow = $iban.closest('.c-form__field').find('.c-form__input-wrapper--shadow input');
    //   var iban_placeholder = 'XXXX XXXX XXXX XXXX XXXX XXXX XXX';
    //   syncShadow($iban, $iban_shadow, iban_placeholder);
    // }

    // var $bic = $('.js-cleave-bic');
    // if ($bic.length > 0) {
    //   var cleaveBic = new Cleave('.js-cleave-bic', {
    //     delimiter: ' ',
    //     blocks: [11],
    //     uppercase: true
    //   });

    //   var $bic_shadow = $bic.closest('.c-form__field').find('.c-form__input-wrapper--shadow input');
    //   var bic_placeholder = 'XXXXXXXX'; // 8 à 11
    //   syncShadow($bic, $bic_shadow, bic_placeholder);
    // }
  });
})(jQuery);
