(function($) {
  $(function() {
    var $loader = $('.js-generate-documents');
    if ($loader.length == 0) {
      return;
    }

    var $documents = $('.js-documents');

    var data = {
      '_wpnonce': $loader.data('nonce')
    };

    $.ajax({
      type: 'POST',
      url: $loader.data('url'),
      data: data,
      success: function(data, status, xhr) {
        try {
          var $html = $(data);
          $loader.hide();
          $documents.html('').append($html.find('.js-document'));
          $('.js-submit').prop('disabled', false);
        }
        catch (error) {
          $loader.hide();
          $('.js-error').show();
        }
      },
      error: function(xhr) {
        // xhr.status
        // xhr.statusText
        // console.debug(xhr);
        $loader.hide();
        $('.js-error').show();
      },
    });
  });
})(jQuery);
