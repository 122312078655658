(function($) {
  $(function() {
    $('.js-form').on('submit', function() {
      $('.js-submit button').prop('disabled', true).hide();
      $('.js-loader').show();

      return true;
    })
  });
})(jQuery);
